'use client';

import { BOOKMARK_TYPE, LIKE_TYPE } from '@/api';
import { useGetRecommendPolicyListQuery } from '@/app/_hooks/useGetRecommendPolicyListQuery';
import { RecommendNewsCard } from '@/components/server';
import { ROUTES } from '@/constants';
import { queryKeys } from '@/constants/query-keys';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useLikeMutation } from '@/hooks/useLikeMutation';
import { useAuthStore } from '@/stores/AuthStore';

import { useBookmarkMutation } from '../../_hooks/useBookmarkMutation';

import { MainArticleWrap } from './MainArticleWrap';
import styles from './RecommendPolicyList.module.scss';

export const RecommendPolicyList = () => {
  const isLogin = useAuthStore((state) => state.isLogin);
  const { data } = useGetRecommendPolicyListQuery();
  const { postBookmark } = useBookmarkMutation({
    queryKey: [queryKeys.recommendPolicyList],
  });
  const { postLike } = useLikeMutation({
    queryKey: [queryKeys.recommendPolicyList],
  });
  const router = useCustomRouter();

  if (!isLogin) return null;

  return (
    <MainArticleWrap
      loadMoreLink={ROUTES.RECOMMEND_POLICY_LIST.pathname}
      loadMoreText="나의 맞춤정책 더보기"
      title={
        <header className={styles['title-header']}>
          <div>
            맞춤정책
            <span> {data?.pages[0].totalCount ?? '-'}</span>
          </div>
          <time className={styles['last-update']}>
            {data?.pages[0].policyDaysAgo}
          </time>
        </header>
      }
    >
      <ul className={styles['recommend-policy-list']}>
        {!data?.pages[0].policyList ? (
          <li className={styles['title-skeleton-container']}>
            <RecommendNewsCard isLoading />
            <RecommendNewsCard isLoading />
            <RecommendNewsCard isLoading />
          </li>
        ) : (
          <>
            {data.pages[0].policyList
              ?.slice(0, 3)
              ?.map(({ id = 0, ...policy }) => (
                <li key={id}>
                  <RecommendNewsCard
                    agency={policy.agency}
                    agencyLogo={policy.agencyLogo}
                    commentCount={policy.commentCount}
                    dateText={policy.dueDateEnd}
                    dday={policy.dday}
                    href={ROUTES.POLICY_DETAIL.pathname({
                      idIdx: policy.idIdx,
                    })}
                    isBookmarked={policy.isWishlist}
                    isThumbUp={policy.isThumbUp}
                    policyName={policy.policyName}
                    supportBenefit={policy.supportBenefit}
                    thumbUpCount={policy.likeCount}
                    onClickBookmark={() =>
                      postBookmark({
                        contentId: id,
                        isBookmarked: policy.isWishlist,
                        bookmarkType: BOOKMARK_TYPE.POLICY,
                      })
                    }
                    onClickComment={() => {
                      router.push(
                        `${ROUTES.POLICY_DETAIL.pathname({
                          idIdx: policy.idIdx,
                        })}#comments`,
                      );
                    }}
                    onClickThumbUp={() =>
                      isLogin
                        ? postLike({
                            contentId: id,
                            isLike: policy.isThumbUp,
                            likeType: LIKE_TYPE.POLICY,
                          })
                        : router.push(ROUTES.LOGIN.pathname)
                    }
                  />
                </li>
              ))}
          </>
        )}
      </ul>
    </MainArticleWrap>
  );
};
