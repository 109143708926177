import Link from 'next/link';

import ServiceCardArrow from '@/assets/svgs/service_card_arrow.svg';
import ServiceCardBg from '@/assets/svgs/service_card_bg.svg';

import styles from './ServiceCard.module.scss';

interface ServiceCardProps {
  serviceType: string;
  backgroundColor: string;
  description: string;
  link: string;
}

const logoSize = {
  width: 32,
  height: 32,
};

export const ServiceCard = ({
  serviceType,
  backgroundColor,
  description,
  link,
}: ServiceCardProps) => {
  const ariaLabel = `${serviceType} - ${description}`;

  return (
    <Link
      aria-label={ariaLabel}
      className={styles['service-card-link']}
      href={link}
    >
      <figure className={`${styles.card} ${styles[backgroundColor]}`}>
        <section>
          <figcaption>{description}</figcaption>
          {serviceType}
        </section>
        <ServiceCardArrow {...logoSize} />
        <ServiceCardBg className={styles['svg-background']} />
      </figure>
    </Link>
  );
};
