'use client';

import CountUp from 'react-countup';

import { useQuery } from '@tanstack/react-query';

import { policyQueryOptions } from '@/query-factory';

import styles from './PolicyCount.module.scss';

export const PolicyCount = () => {
  const { data } = useQuery(policyQueryOptions.count());

  return (
    <article className={styles.statistics}>
      <section className={styles['statistics-section']}>
        <h3 className={styles['statistics-title']}>누적 정책</h3>
        <CountUp
          className={styles['statistics-value']}
          end={data?.context?.total_policy_count ?? 0}
          suffix="+"
        />
      </section>
      <section className={styles['statistics-section']}>
        <h3 className={styles['statistics-title']}>정보 제공기관</h3>
        <CountUp
          className={styles['statistics-value']}
          end={data?.context?.total_agency_count ?? 0}
          suffix="+"
        />
      </section>
    </article>
  );
};
