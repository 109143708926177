// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
'use client';

import { useCallback } from 'react';
import { ChevronRight, Crosshair } from 'react-feather';

import Link from 'next/link';

import { BOOKMARK_TYPE } from '@/api';
import { Button, Skeleton } from '@/components/server';
import { NewsSmallCard } from '@/components/server/Card/NewsSmallCard';
import { ROUTES } from '@/constants';
import { queryKeys } from '@/constants/query-keys';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useGeolocation } from '@/hooks/useGeolocation';
import { useGetHometownListQuery } from '@/hooks/useGetHometownListQuery';
import { useToast } from '@/hooks/useToast';
import { useAuthStore } from '@/stores/AuthStore';

import { useBookmarkMutation } from '../../_hooks/useBookmarkMutation';

import { MainArticleWrap } from './MainArticleWrap';
import styles from './NewsSmallCardPolicyInfoList.module.scss';

export const NewsSmallCardHometownNewsList = () => {
  const setRegion = useAuthStore((state) => state.setRegion);
  const { location, refetch, isFetching } = useGeolocation();
  const { toast, toastError } = useToast();
  const { data } = useGetHometownListQuery({
    codeRegion: location.codeRegion,
    codeSubRegion: location.codeSubRegion,
  });
  const { postBookmark } = useBookmarkMutation({
    queryKey: [
      queryKeys.hometownNewsList,
      location.codeRegion,
      location.codeSubRegion,
    ],
  });
  const isLogin = useAuthStore((state) => state.isLogin);
  const router = useCustomRouter();

  const handleCurrentLocation = useCallback(async () => {
    try {
      const { data } = await refetch();
      if (!data) throw Error();
      toast({ message: '현재 위치를 불러왔어요' });
      setRegion(data);
    } catch (error) {
      toastError({ error });
    }
  }, [refetch, setRegion, toast, toastError]);

  return (
    <MainArticleWrap
      loadMoreLink={ROUTES.HOMETOWN_NEWS.pathname}
      loadMoreText="동네소식 더보기"
      title="동네소식"
    >
      <header className={styles['location-top']}>
        {!data?.pages[0].contents ? (
          <>
            <Skeleton className={styles['hometown-skeleton-header']} />
            <Skeleton className={styles['hometown-skeleton-header']} />
          </>
        ) : (
          <>
            <Link href={ROUTES.HOMETOWN_NEWS_SEARCH.pathname}>
              {location.subRegion}
              <ChevronRight className={styles.rotate} />
            </Link>
            <Button
              className={styles['current-location-choice']}
              icon={
                !isFetching ? <Crosshair className={styles.target} /> : null
              }
              loading={isFetching}
              size="small"
              theme="unset"
              onClick={handleCurrentLocation}
            >
              현재위치
            </Button>
          </>
        )}
      </header>

      <ul className={styles['news-small-card-policy-info-list']}>
        {!data?.pages[0]?.contents ? (
          <li className={styles['title-skeleton-container']}>
            <NewsSmallCard isLoading />
            <NewsSmallCard isLoading />
            <NewsSmallCard isLoading />
          </li>
        ) : (
          <>
            {data.pages[0].contents.slice(0, 3).map((news) => {
              return (
                <li key={news.id}>
                  <NewsSmallCard
                    {...news}
                    dateText={`${news.dateText} 작성`}
                    href={ROUTES.HOMETOWN_NEWS_DETAIL.pathname({
                      idIdx: news.idIdx,
                    })}
                    thumbnail={news.thumbnail}
                    onClickBookmark={() =>
                      isLogin
                        ? postBookmark({
                            contentId: news.id,
                            isBookmarked: news.isBookmarked,
                            bookmarkType: BOOKMARK_TYPE.HOMETOWN_NEWS,
                          })
                        : router.push(ROUTES.LOGIN.pathname)
                    }
                  />
                </li>
              );
            })}
          </>
        )}
      </ul>
    </MainArticleWrap>
  );
};
