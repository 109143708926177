'use client';

import { useState, useEffect } from 'react';

import { Icon } from '@wello-client/common';

import { Input, InputWrap } from '@/components/client';
import { Button } from '@/components/server';

import styles from './TestInputCi.module.scss';

export const TestInputCi = () => {
  const [ci, setCi] = useState('');
  const [storedCi, setStoredCi] = useState<string | null>(null);
  const [isProd, setIsProd] = useState(false);

  useEffect(() => {
    const savedCi = localStorage.getItem('test_ci');
    if (savedCi) {
      setStoredCi(savedCi);
    }

    const url = window.location.href;
    if (url.includes('stage') || url.includes('localhost')) {
      setIsProd(false);
    } else {
      setIsProd(true);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCi(e.target.value);
  };

  const handleSaveCi = () => {
    localStorage.setItem('test_ci', ci);
    setStoredCi(ci);
  };

  const handleResetCi = () => {
    localStorage.removeItem('test_ci');
    setStoredCi(null);
    setCi('');
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveCi();
    }
  };

  if (isProd) return <></>;

  return (
    <div className={styles['input-wrap']}>
      <InputWrap>
        <Input
          placeholder="테스트 하실 CI를 입력해주세요"
          value={ci}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        <Button theme="unset" onClick={handleSaveCi}>
          <Icon name="Mail-Send-Email-Message" />
        </Button>
      </InputWrap>
      {storedCi ? (
        <div className={styles['stored-ci']}>
          <p>저장된 CI: {storedCi}</p>
          <Button theme="unset" onClick={handleResetCi}>
            초기화
          </Button>
        </div>
      ) : null}
    </div>
  );
};
