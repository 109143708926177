'use client';

import type { ListWelloPickDto } from '@generatedapi/auth';

import Link from 'next/link';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide, type SwiperProps } from 'swiper/react';

import { ROUTES } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';

import { UserCard } from './UserCard';
import styles from './UserCardList.module.scss';

interface UserCardListProps {
  summary?: string;
  userCardList?: ListWelloPickDto[];
}

const SWIPER_PROPS = {
  grabCursor: true,
  effect: 'creative',
  slidesPerView: 2,
  spaceBetween: 0,
  breakpoints: {
    400: {
      slidesPerView: 3,
      spaceBetween: '10px',
    },
    300: {
      slidesPerView: 2,
      spaceBetween: '10px',
    },
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  modules: [Autoplay],
} satisfies SwiperProps;

export const UserCardList = ({ userCardList }: UserCardListProps) => {
  const isLogin = useAuthStore((state) => state.isLogin);

  if (isLogin) return <></>;

  return (
    <section>
      <h2 className={styles.title}>정책 혜택을 발견하세요!</h2>
      <Swiper {...SWIPER_PROPS} className={styles.cards}>
        {userCardList?.map((card) => (
          <SwiperSlide key={card.title}>
            <UserCard card={card} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Link
        className={styles['load-more']}
        href={ROUTES.MY_POLICY_FILTER.pathname}
      >
        맞춤 정보 입력하고, 내 혜택 찾아보기
      </Link>
    </section>
  );
};
