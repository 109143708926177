'use client';

import { Swiper, SwiperSlide, type SwiperProps } from 'swiper/react';

import { ROUTES } from '@/constants';

import { ServiceCard } from './ServiceCard';
import styles from './ServiceCardList.module.scss';

const SWIPER_PROPS = {
  grabCursor: true,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 8,
} satisfies SwiperProps;

export const ServiceCardList = () => {
  return (
    <section className={styles['service-card-list']}>
      <Swiper {...SWIPER_PROPS} className={styles['swiper-list']}>
        {cardList.map((service, index) => (
          <SwiperSlide key={index} className={styles.card}>
            <ServiceCard {...service} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

const cardList = [
  {
    serviceType: '맞춤정책',
    description: '나에게 딱 맞는',
    backgroundColor: 'recommend-policy',
    link: ROUTES.RECOMMEND_POLICY_LIST.pathname,
  },
  {
    serviceType: '동네소식',
    description: '지역의 다양한 정책',
    backgroundColor: 'hometown-news',
    link: ROUTES.HOMETOWN_NEWS.pathname,
  },
  {
    serviceType: '정책소식',
    description: '매일 업데이트 되는',
    backgroundColor: 'policy-news',
    link: ROUTES.POLICY_INFO_LIST.pathname,
  },
  {
    serviceType: '댓글 활동',
    description: '웰로 소식에 대한',
    backgroundColor: 'comment-activity',
    link: ROUTES.PROFILE_TAB.withSearchParams({
      searchParams: { tab: 'comment' },
    }),
  },
  {
    serviceType: '스크랩 활동',
    description: '유익한 정보 수집을 위한',
    backgroundColor: 'scrap',
    link: ROUTES.PROFILE_TAB.withSearchParams({
      searchParams: { tab: 'scrap' },
    }),
  },
];
