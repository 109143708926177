import { ChevronRight } from 'react-feather';

import { cleanClassName } from '@wello-client/common/utils';
import Link from 'next/link';

import styles from './MainArticleWrap.module.scss';

interface MainArticleWrapProps {
  title: React.ReactNode;
  children: React.ReactNode;
  loadMoreLink?: string;
  loadMoreText?: string;
  className?: string;
  loadMoreTheme?: 'default' | 'primary';
}

export const MainArticleWrap = ({
  title,
  children,
  loadMoreLink,
  loadMoreText,
  className,
  loadMoreTheme,
}: MainArticleWrapProps) => {
  return (
    <article
      className={cleanClassName(`${styles['main-article']} ${className}`)}
    >
      <section>
        <h2 className={styles.title}>{title}</h2>
        {children}
      </section>
      {loadMoreLink ? (
        <Link
          className={cleanClassName(
            `${styles['load-more']} ${styles[loadMoreTheme || 'default']}`,
          )}
          href={loadMoreLink}
        >
          {loadMoreText} <ChevronRight className={styles.more} />
        </Link>
      ) : null}
    </article>
  );
};
