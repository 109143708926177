import { Bookmark } from 'react-feather';

import { cleanClassName } from '@wello-client/common/utils';
import Link from 'next/link';

import { LazyImage } from '@/components/client';
import { Button, Skeleton } from '@/components/server';

import styles from './NewsSmallCard.module.scss';

interface NewsSmallCardProps {
  title?: string;
  dateText?: string;
  thumbnail?: string;
  border?: boolean;
  href?: string;
  isLoading?: boolean;
  isBookmarked?: boolean;
  onClickBookmark?: () => void;
}

export const NewsSmallCard = ({
  border,
  title,
  dateText,
  href = '#',
  isLoading,
  thumbnail,
  isBookmarked,
  onClickBookmark,
}: NewsSmallCardProps) => {
  return (
    <article
      className={cleanClassName(
        `${styles['news-small-card']} ${border && styles['news-card-border']}`,
      )}
    >
      <Link className={styles.link} href={href}>
        <LazyImage
          alt={`news-small-card-${title}`}
          className={styles.thumbnail}
          isLoading={isLoading}
          src={thumbnail}
        />
        <div className={styles['card-info']}>
          {isLoading ? (
            <div className={styles['title-skeleton-container']}>
              <Skeleton className={styles['title-skeleton']} />
              <Skeleton className={styles['title-skeleton']} />
            </div>
          ) : (
            <h3 className={styles.title}>{title}</h3>
          )}
          {isLoading ? (
            <Skeleton className={styles['date-text-skeleton']} />
          ) : (
            <p className={styles['date-text']}>{dateText}</p>
          )}
        </div>
      </Link>
      <Button
        aria-label={isBookmarked ? '북마크 제거' : '북마크 추가'}
        className={styles['bookmark-button']}
        theme="unset"
        onClick={onClickBookmark}
      >
        {isLoading ? (
          <Skeleton className={styles['icon-skeleton']} />
        ) : (
          <Bookmark
            className={cleanClassName(
              `${styles.icon} ${isBookmarked && styles.on}`,
            )}
          />
        )}
      </Button>
    </article>
  );
};
