'use client';

import type { UnifiedTabType } from '@/types';
import type { SwiperProps } from 'swiper/react';

import { useState } from 'react';
import { RotateCcw } from 'react-feather';

import { useQuery } from '@tanstack/react-query';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { LIKE_TYPE } from '@/api';
import { Tab, TabItem } from '@/components/client';
import { Button } from '@/components/server';
import {
  ROUTES,
  tabLabels as originalTabLabels,
  tabs as originalTabs,
  tabToCommentTypeMap,
  tabToRouteMap,
} from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useLikeMutation } from '@/hooks/useLikeMutation';
import { useToast } from '@/hooks/useToast';
import { commentQueryOptions } from '@/query-factory';
import { useAuthStore } from '@/stores/AuthStore';

import { LiveComment } from './LiveComment';
import styles from './LivePolicy.module.scss';

const SWIPER_PROPS = {
  centeredSlides: true,
  effect: 'creative',
  spaceBetween: 20,
  grabCursor: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  modules: [Autoplay],
} satisfies SwiperProps;

const tabsWithAll = ['all', ...originalTabs] as const;
const tabLabelsWithAll = { all: '전체', ...originalTabLabels };

export const LivePolicy = () => {
  const isLogin = useAuthStore((state) => state.isLogin);
  const [activeTab, setActiveTab] = useState<UnifiedTabType>('all');
  const { toast, toastError } = useToast();
  const { data, refetch, isRefetching } = useQuery(
    commentQueryOptions.liveList({
      commentTypeCd: tabToCommentTypeMap[activeTab],
      isLogin,
    }),
  );
  const router = useCustomRouter();
  const { postLike } = useLikeMutation({
    queryKey: commentQueryOptions.liveList({
      commentTypeCd: tabToCommentTypeMap[activeTab],
      isLogin,
    }).queryKey,
  });

  const handleToggleRotate = () => {
    refetch()
      .then(() => {
        toast({ message: '실시간 의견을 불러왔어요' });
      })
      .catch((error) => {
        toastError({ error });
      });
  };

  const handleTabClick = (tab: UnifiedTabType) => {
    setActiveTab(() => tab);
  };

  const filteredData =
    activeTab === 'all'
      ? data?.liveWelloCommentList
      : data?.liveWelloCommentList?.filter(
          (comment) => comment.commentTypeCd === tabToCommentTypeMap[activeTab],
        );

  return (
    <section className={styles['live-wrap']}>
      <h2>
        <span className={styles['live-title']}>
          실시간 정책 의견
          <Button
            className={styles['rotate-wrap']}
            theme="unset"
            type="button"
            onClick={handleToggleRotate}
          >
            <RotateCcw key={`${isRefetching}`} className={styles.rotated} />
            실시간 확인
          </Button>
        </span>
      </h2>
      <Tab className={styles.tab} shape="capsule" size="small">
        {tabsWithAll.map((tab) => (
          <TabItem
            key={tab}
            active={activeTab === tab}
            theme="sub-fill"
            onClick={() => handleTabClick(tab)}
          >
            {tabLabelsWithAll[tab]}
          </TabItem>
        ))}
      </Tab>
      <Swiper {...SWIPER_PROPS}>
        {filteredData === undefined ? (
          <SwiperSlide>
            <LiveComment isLoading />
          </SwiperSlide>
        ) : (
          filteredData.map((live, index) => {
            if (!live.idIdx) return null;

            return (
              <SwiperSlide key={index}>
                <LiveComment
                  {...live}
                  href={
                    tabToRouteMap(`${live.idIdx}#comments`)[live.commentTypeCd]
                  }
                  onClickComment={() => {
                    router.push(
                      tabToRouteMap(`${live.idIdx}#comments`)[
                        live.commentTypeCd
                      ],
                    );
                  }}
                  onClickThumbUp={() => {
                    isLogin
                      ? postLike({
                          contentId: live.commentId,
                          isLike: live.isMyThumbUp,
                          likeType: LIKE_TYPE.COMMENT,
                        })
                      : router.push(ROUTES.LOGIN.pathname);
                  }}
                />
              </SwiperSlide>
            );
          })
        )}
      </Swiper>
    </section>
  );
};
