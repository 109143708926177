import type { ListWelloPickDto } from '@generatedapi/unauth';

import Link from 'next/link';

import { LazyImage } from '@/components/client';
import { ROUTES } from '@/constants/routes';

import styles from './UserCard.module.scss';

interface CardComponentProps {
  card: ListWelloPickDto;
}

export const UserCard = ({ card }: CardComponentProps) => {
  //TODO: 임시로 이미지 변경
  const tempUrl = {
    'https://cdn.welfarehello.com/production/contents/pick/E4cyTasvELZhcp1m.png':
      'https://cdn.welfarehello.com/production/contents/pick/CwSe0392svGj6YWV.png',
    'https://cdn.welfarehello.com/production/contents/pick/Ax4Vbo71hK1QQbUd.png':
      'https://cdn.welfarehello.com/production/contents/pick/rNDb8Xb6ThJAxTM5.png',
    'https://cdn.welfarehello.com/production/contents/pick/Ou93sFn38EtjNnC2.png':
      'https://cdn.welfarehello.com/production/contents/pick/Ypg419UTrXZ0Un9s.png',
    'https://cdn.welfarehello.com/production/contents/pick/05MSkFFqHJHOz6kH.png':
      'https://cdn.welfarehello.com/production/contents/pick/Xn7UunhvsaaEViLU.png',
    'https://cdn.welfarehello.com/production/contents/pick/rVPTv2Bz9hiyEoPi.png':
      'https://cdn.welfarehello.com/production/contents/pick/3r4v3I5KMvDo0gPp.png',
    'https://cdn.welfarehello.com/production/contents/pick/EX97J4bD3QyoQzdT.png':
      'https://cdn.welfarehello.com/production/contents/pick/do9W2M73I93B966f.png',
  }[card.file_url as string];

  return (
    <Link
      className={styles['pick-link']}
      href={ROUTES.PICK_POLICY_LIST.pathname({ id: card.wello_pick_id ?? 0 })}
    >
      <figure
        className={`${styles.card} ${card.title && styles[`${card.title}-dynamic`]}`}
      >
        <LazyImage src={tempUrl ?? card.file_url} />
        <figcaption className={styles['card-caption']}>
          <h3>{card.title}</h3>
          <p>평균 수혜금액</p>
          <strong className={styles.value}>{card.contents}</strong>
        </figcaption>
      </figure>
    </Link>
  );
};
