'use client';

import { cleanClassName } from '@wello-client/common/utils';

import { BOOKMARK_TYPE, LIKE_TYPE } from '@/api';
import { useGetRecommendPolicyListQuery } from '@/app/_hooks/useGetRecommendPolicyListQuery';
import New from '@/assets/svgs/new.svg';
import { RecommendNewsCard } from '@/components/server';
import { ROUTES } from '@/constants';
import { queryKeys } from '@/constants/query-keys';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useLikeMutation } from '@/hooks/useLikeMutation';
import { useAuthStore } from '@/stores/AuthStore';

import { useBookmarkMutation } from '../../_hooks/useBookmarkMutation';

import { MainArticleWrap } from './MainArticleWrap';
import styles from './NewPolicyList.module.scss';

interface NewPolicyListProps {
  welloMemberFamilyId?: number;
}

export const NewPolicyList = ({
  welloMemberFamilyId,
}: NewPolicyListProps = {}) => {
  const isLogin = useAuthStore((state) => state.isLogin);

  const { data, isLoading } = useGetRecommendPolicyListQuery({
    welloMemberFamilyId,
  });

  const { postBookmark } = useBookmarkMutation({
    queryKey: [queryKeys.recommendPolicyList],
  });
  const { postLike } = useLikeMutation({
    queryKey: [queryKeys.recommendPolicyList],
  });
  const router = useCustomRouter();

  return (
    <MainArticleWrap
      className={cleanClassName(
        `${styles.card} ${styles.new} ${!data?.pages[0].newPolicyList?.length && styles['display-none']}`,
      )}
      loadMoreLink={ROUTES.RECOMMEND_POLICY_LIST.pathname}
      loadMoreText="신규정책 더보기"
      title={
        <div className={styles.title}>
          신규 정책 <New />
        </div>
      }
    >
      <ul className={styles['policy-list']}>
        {data?.pages[0].newPolicyList?.slice(0, 3).map((policy, index) => (
          <li key={index}>
            <RecommendNewsCard
              border
              agency={policy.agency}
              agencyLogo={policy.agencyLogo}
              commentCount={policy.commentCount}
              dateText={`${policy.dueDateEnd}`}
              dday={policy.dday}
              href={ROUTES.POLICY_DETAIL.pathname({ idIdx: policy.idIdx })}
              isBookmarked={policy.isWishlist}
              isThumbUp={policy.isThumbUp}
              policyName={policy.policyName}
              supportBenefit={`${policy.supportBenefit}`}
              thumbUpCount={policy.likeCount}
              onClickBookmark={() =>
                postBookmark({
                  contentId: policy.id,
                  isBookmarked: policy.isWishlist,
                  bookmarkType: BOOKMARK_TYPE.POLICY,
                })
              }
              onClickComment={() => {
                router.push(
                  `${ROUTES.POLICY_DETAIL.pathname({
                    idIdx: policy.idIdx,
                  })}#comments`,
                );
              }}
              onClickThumbUp={() =>
                isLogin
                  ? postLike({
                      contentId: policy.id,
                      isLike: policy.isThumbUp,
                      likeType: LIKE_TYPE.POLICY,
                    })
                  : router.push(ROUTES.LOGIN.pathname)
              }
            />
          </li>
        ))}
        {isLoading
          ? new Array(NEW_POLICY_COUNT).fill({}).map((_, index) => {
              return (
                <li key={index}>
                  <RecommendNewsCard isLoading />
                </li>
              );
            })
          : null}
      </ul>
    </MainArticleWrap>
  );
};

const NEW_POLICY_COUNT = 1;
