import type { LiveCommentQueryProps } from '@/query-factory';

import { ThumbsUp } from 'react-feather';

import { cleanClassName } from '@wello-client/common/utils';
import Link from 'next/link';

import CommentIcon from '@/assets/svgs/comment_off.svg';
import { LazyImage } from '@/components/client';
import { Badge, Button, Skeleton } from '@/components/server';

import styles from './LivePolicy.module.scss';

export const LiveComment = ({
  title,
  contents,
  nickName,
  isMyThumbUp,
  profileImage,
  isBest,
  isLoading,
  commentType,
  dateTime,
  thumbUpCount,
  commentCount,
  isDeleted,
  onClickThumbUp,
  onClickComment,
  href = '#',
}: LiveCommentQueryProps) => {
  return (
    <article className={styles['live-comment-wrap']}>
      <Link className={styles['live-comment']} href={href}>
        <div>
          <header className={styles['live-comment-header']}>
            {isLoading ? (
              <>
                <Skeleton className={styles['hometown-skeleton-header']} />
                <Skeleton className={styles['hometown-skeleton-header']} />
              </>
            ) : (
              <>
                <span>
                  <LazyImage
                    alt="유저 프로필 이미지"
                    className={styles['profile-image']}
                    src={profileImage}
                  />
                  <h3>{nickName}</h3>
                </span>
                <time dateTime={dateTime}>{dateTime}</time>
              </>
            )}
          </header>
          <section className={styles['live-comment-content']}>
            {isLoading ? (
              <>
                <Skeleton className={styles['text-skeleton']} />
                <Skeleton className={styles['text-skeleton-second']} />
              </>
            ) : (
              <div>
                {isBest ? <Badge color="red">BEST</Badge> : null}
                <span
                  className={cleanClassName(
                    `${styles['live-comment-contents']} ${isDeleted && styles.deleted}`,
                  )}
                >
                  {isDeleted ? '작성자에 의해 삭제된 댓글입니다.' : contents}
                </span>
              </div>
            )}
          </section>
        </div>
        <span className={styles['live-comment-title']}>
          {isLoading ? (
            <Skeleton className={styles['text-skeleton']} />
          ) : (
            <>
              <Badge color="primary">{commentType}</Badge>
              <span>{title}</span>
            </>
          )}
        </span>
      </Link>
      <footer className={styles['live-comment-footer']}>
        <Button
          aria-label="좋아요"
          className={styles['thumb-up-button']}
          theme="unset"
          type="button"
          onClick={onClickThumbUp}
        >
          {isLoading ? (
            <Skeleton className={styles['icon-skeleton']} />
          ) : (
            <>
              <ThumbsUp
                className={cleanClassName(
                  `${styles.icon} ${isMyThumbUp && styles.on}`,
                )}
              />
              {thumbUpCount}
            </>
          )}
        </Button>
        <Button
          aria-label="댓글"
          className={styles['reply-button']}
          theme="unset"
          type="button"
          onClick={onClickComment}
        >
          {isLoading ? (
            <Skeleton className={styles['icon-skeleton']} />
          ) : (
            <>
              <CommentIcon className={styles.icon} />
              {commentCount}
            </>
          )}
        </Button>
      </footer>
    </article>
  );
};
