'use client';

import { UserPlus } from 'react-feather';

import { useQuery } from '@tanstack/react-query';
import { cleanClassName } from '@wello-client/common/utils';

import { Accordion } from '@/components/client';
import { Button } from '@/components/server';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { familyQueryOptions } from '@/query-factory';
import { useAuthStore } from '@/stores/AuthStore';
import { FAMILY_PROFILE_UPDATE_PAGE_TYPE } from '@/types';

import styles from './FamilyPolicy.module.scss';

export const FamilyPolicy = () => {
  const isLogin = useAuthStore((state) => !!state.isLogin);
  const { data } = useQuery(familyQueryOptions.list({ isLogin }));
  const router = useCustomRouter();

  return (
    <section className={styles['family-wrap']}>
      <Accordion opened>
        <Accordion.Title className={styles['family-title']}>
          가족정책
          {data ? (
            <span className={styles['family-count']}>
              {data.totalPolicyCount?.toLocaleString()}
            </span>
          ) : null}
        </Accordion.Title>
        <Accordion.Contents
          className={cleanClassName(
            `${styles['accordion-contents']} ${styles['family-contents']}`,
          )}
        >
          {data?.familyList ? (
            <ul className={styles['family-list']}>
              {data.familyList.map((family) => {
                return (
                  <li
                    key={family.wello_member_family_id}
                    onClick={() =>
                      router.push(
                        ROUTES.RECOMMEND_POLICY_LIST.withSearchParams({
                          searchParams: {
                            familyId: family.wello_member_family_id,
                          },
                        }),
                      )
                    }
                  >
                    <span>{family.nick_name}</span>
                    <span className={styles['family-member-count']}>
                      <b>{family.member_policy_cnt}</b>건
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <section className={styles['empty-content']}>
              <UserPlus className={styles['user-plus-icon']} />
              <h3>가족을 등록해주세요</h3>
              <span>내 가족에게 딱 맞는 정책을 더 보여드릴게요!</span>
            </section>
          )}

          <Button
            theme="sub-outline"
            onClick={() =>
              router.push(
                ROUTES.FAMILY_PROFILE_UPDATE.pathname({
                  updateType: FAMILY_PROFILE_UPDATE_PAGE_TYPE.ADD,
                }),
              )
            }
          >
            가족 추가
          </Button>
        </Accordion.Contents>
      </Accordion>
    </section>
  );
};
